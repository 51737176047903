import moment from 'moment';

export const lineChartOption = {
  scales: {
    y:
      {
        ticks: {
          maxTicksLimit: 5,
          fontColor: '#637178',
          precision: 0,
        }
      },
    x:
      {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
          maxRotation: 30,
          minRotation: 0,
          fontColor: '#637178',
        }
      }
  },
  elements: {
    point:{
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false
  }
}

export const singleLineChartOption = {
  scales: {
    y:
      {
        ticks: {
          maxTicksLimit: 5,
          fontColor: '#637178',
          precision: 0,
          callback: function(label, _index, _labels) {
            return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        }
      },
    x:
      {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
          maxRotation: 30,
          minRotation: 0,
          fontColor: '#637178',
        }
      }
  },
  elements: {
    point:{
      hitRadius: 10,
      hoverRadius: 4
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend : {
      display : false
    }
  },
  tooltip: {
    callbacks: {
      label: function(tooltipItem, data) {
        return Math.round(tooltipItem.yLabel * 100) / 100
      }
    }
  },
  interaction: {
    intersect: false
  }
}

export const PercentageChartOption = {
  scales: {
    y:
      {
        ticks: {
          maxTicksLimit: 5,
          fontColor: '#637178',
          precision: 0,
          callback: function(label, _index, _labels) {
            return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "%";
          }
        }
      },
    x:
      {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
          maxRotation: 30,
          minRotation: 0,
          fontColor: '#637178',
        }
      }
  },
  elements: {
    point:{
      hitRadius: 10,
      hoverRadius: 4
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend : {
      display : false
    }
  },
  tooltip: {
    callbacks: {
      label: function(tooltipItem, data) {
        return Math.round(tooltipItem.yLabel * 100) / 100 + "%"
      }
    }
  },
  interaction: {
    intersect: false
  }
}

export const horizontalBarChartOption = {
  indexAxis: 'y',
  scales: {
    y:
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#637178',
          precision: 0,
        },
        stacked: true
      },
    x:
      {
        grid: {
          display: true,
          color: '#E3E8EB',
        },
        ticks: {
          min: 0,
          fontColor: '#637178',
        },
        stacked: true
      }
  },
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend : {
      display : false
    }
  },
  interaction: {
    intersect: false
  }
}

export function getAgeIndex(age) {
  switch(age) {
    case '13-17':
      return 0
    case '18-24':
      return 1
    case '25-34':
      return 2
    case '35-44':
      return 3
    case '45-54':
      return 4
    case '55-64':
      return 5
    case '65+':
      return 6
  }
}

export function getSexIndex(sex) {
  switch(sex) {
    case 'M':
      return 0
    case 'F':
      return 1
    case 'U':
      return 2
  }
}

export const lineChartColors = {
  'AD': 'rgba(122, 128, 201, 1)',
  'FEED': 'rgba(192, 64, 134, 1)',
  'REEL': 'rgb(255, 99, 132)',
  'STORY': 'rgb(255, 159, 64)',
  'CAROUSEL_CONTAINER': 'rgb(255, 205, 86)',
  'POST': 'rgb(75, 192, 192)',
  'IGTV': 'rgb(153, 102, 255)',
  'LIVE': 'rgb(75, 192, 192)',
  'AR_EFFECT_PREVIEW': 'rgb(54, 162, 235)',

  'FOLLOWER':  'rgba(122, 128, 201, 1)',
  'NON_FOLLOWER': 'rgba(192, 64, 134, 1)',

  'BOOK_NOW': 'rgba(122, 128, 201, 1)',
  'CALL': 'rgba(192, 64, 134, 1)',
  'DIRECTION': 'rgb(255, 99, 132)',
  'EMAIL': 'rgb(255, 159, 64)',
  'INSTANT_EXPERIENCE': 'rgb(255, 205, 86)',
  'TEXT': 'rgb(75, 192, 192)',
  'UNDEFINED': 'rgb(153, 102, 255)'
}

export function getAllLabelsFromData(rawData) {
  let labels = []
  rawData.forEach(data => {
    data.points.forEach(point => {
      let key = point.dimension_values[0]
      if(!labels.includes(key)){
        labels.push(key)
      }
    })
  })
  return labels
}

export function genderCodeToText(genderCode) {
  switch(genderCode) {
    case 'M':
      return '男性'
    case 'F':
      return '女性'
    case 'U':
      return 'その他'
    default:
      return ''
  }
}

export function getDateRange(startDate, endDate, dateUnit){
  switch(dateUnit) {
    case 'day':
      return getAllDays(startDate, endDate)
    case 'week':
      return getMondays(startDate, endDate)
    case 'month':
      return getMonths(startDate, endDate)
  }
}

function getAllDays(startDate, endDate) {
  let dates = []
  let now = startDate.clone();

  while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('YYYY/MM/DD'));
      now.add(1, 'days');
  }
  return dates;
};

function getMondays(startDate, endDate){
  let arr = []
  // Get "next" monday
  let tmp = startDate.clone().day(1)
  if( tmp.isAfter(startDate, 'd') ){
    arr.push(tmp.format('YYYY/MM/DD'))
  }
  while( tmp.isBefore(endDate) ){
    arr.push(tmp.format('YYYY/MM/DD'))
    tmp.add(7, 'days')
  }
  return arr
}

function getMonths(startDate, endDate){
  let arr = [];

  if (startDate < endDate){
    let date = startDate.startOf('month')

    while (date < endDate.endOf('month')) {
      arr.push(date.format('YYYY/MM'))
      date.add(1,'month');
    }
  }
  return arr
}
