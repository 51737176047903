import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.addElementListener();
  }

  addElementListener() {
    this.element.addEventListener('click', this.handleAddElement.bind(this));
  }

  cloneElement(selector, index, prefix, copy = false) {
    let elements = document.querySelectorAll(selector);
    let lastElement = elements[elements.length - 1];
    let newElement = lastElement.cloneNode(true);
  
    let inputElement = newElement.querySelector('input');
    inputElement.name = `${prefix}-${index}`;
    inputElement.id = `${prefix}-${index}`;

    let deleteElement = newElement.querySelector('[class^="delete-icon"]');
    deleteElement.classList.remove(`delete-icon-${index-1}`);
    deleteElement.classList.add(`delete-icon-${index}`);

    if(copy) {
      if(selector === '.keyword-search'){
        inputElement.value = lastElement.querySelector('input') ? lastElement.querySelector('input').value : '';
      }else if(selector === '.daterange-picker'){
        let stringDate = lastElement.querySelector('input').value;
        let from_date = stringDate.split(' から ')[1];
        let to_date = stringDate.split(' から ')[0];
        newElement.setAttribute('data-from-date', from_date);
        newElement.setAttribute('data-to-date', to_date);
      }
    } else {
      if(selector === '.keyword-search'){
        inputElement.value = '';
      }else if(selector === '.daterange-picker'){
        newElement.removeAttribute('data-from-date');
        newElement.removeAttribute('data-to-date');
      }
    }
  
    let addButton = newElement.querySelector('.add-condition');
    if (addButton) {
      let checkbox = addButton.querySelector(`.toggle-switch input[type="checkbox"]`);
      if (checkbox) {
        let lastCheckbox = lastElement.querySelector(`input[type="checkbox"]`);
        if(copy){
            checkbox.checked = lastCheckbox.checked? lastCheckbox.checked : false;
            if (!checkbox.checked) {
            checkbox.removeAttribute('checked');
            } else {
            checkbox.setAttribute('checked', 'checked');
            }
        }else{
            checkbox.checked = true;
            checkbox.setAttribute('checked', 'checked');
        }
  
        checkbox.id = `${prefix}-open-checkbox-${index}`;
        checkbox.name = `${prefix}-open-checkbox-${index}`;
        let checkboxLabel = addButton.querySelector(`.toggle-switch label`);
        if (checkboxLabel) {
          checkboxLabel.htmlFor = `${prefix}-open-checkbox-${index}`;
        }
      }
  
      let andAddition = addButton.querySelector(`.and-condition input`);
      if (andAddition) {
        let lastAndAddition = lastElement.querySelector(`.and-condition input`);
        if(copy) andAddition.value = lastAndAddition ? lastAndAddition.value : '';
        else andAddition.value = "";
        andAddition.name = `${prefix}-and-keyword-${index}`;
        andAddition.id = `${prefix}-and-keyword-${index}`;
      }
  
      let orAddition = addButton.querySelector(`.or-condition input`);
      if (orAddition) {
        let lastOrAddition = lastElement.querySelector(`.or-condition input`);
        if(copy) orAddition.value = lastOrAddition ? lastOrAddition.value : '';
        else orAddition.value = "";
        orAddition.name = `${prefix}-or-keyword-${index}`;
        orAddition.id = `${prefix}-or-keyword-${index}`;
      }
  
      let notAddition = addButton.querySelector(`.not-condition input`);
      if (notAddition) {
        let lastNotAddition = lastElement.querySelector(`.not-condition input`);
        if(copy) notAddition.value = lastNotAddition ? lastNotAddition.value : '';
        else notAddition.value = "";
        notAddition.name = `${prefix}-not-keyword-${index}`;
        notAddition.id = `${prefix}-not-keyword-${index}`;
      }
    }
  
    lastElement.insertAdjacentElement('afterend', newElement);
  }
  getNextNumber(element){
    const inputElement = element[element.length - 1].querySelector('input');
    let number = parseInt(inputElement.name.split('-')[1]) + 1;
    return number;
  }

  handleAddElement(event) {
    const keywordSearchElements = document.querySelectorAll('.keyword-search');
    let numberKeywords = keywordSearchElements.length;
    const dateRangeElements = document.querySelectorAll('.daterange-picker');
    let numberDateRanges = dateRangeElements.length;

    if (numberKeywords >= 2 || numberDateRanges >= 2) {
      document.querySelector('.add-keywords').style.display = 'none';
      document.querySelector('.add-daterange').style.display = 'none';
    }

    let numberIndex = this.getNextNumber(keywordSearchElements);

    if(event.target.closest('.add-keywords')) {
        this.cloneElement('.keyword-search', numberIndex, 'keyword', false);
        this.cloneElement('.daterange-picker', numberIndex, 'daterange', true);
    }else if (event.target.closest('.add-daterange')) {
        this.cloneElement('.keyword-search', numberIndex, 'keyword', true);
        this.cloneElement('.daterange-picker', numberIndex, 'daterange', false);
    }

    const deleteElement = document.querySelectorAll('[class^="delete-icon"]');
    deleteElement.forEach(element => {
      element.style.display = 'block'; 
    });
  }
}
