import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'displayMediaCount',
    'progressCount',
    'mediaUsageRate'
  ]

  static values = {
    maxMediaCount: Number,
    mediaCount: Object
  }

  async connect() {
    const displaySpan = this.displayMediaCountTarget.querySelector('span')
    if (Object.keys(this.mediaCountValue).length == 0){
      displaySpan.innerText = `- / ${this.maxMediaCountValue.toLocaleString()}`
      this.mediaUsageRateTarget.innerText = '-'
      return
    }
    const tagHistoryCount = this.mediaCountValue.tag_history_count
    const mentionedMediaCount = this.mediaCountValue.mentioned_media_count
    const total = tagHistoryCount + mentionedMediaCount

    displaySpan.innerText = `${total.toLocaleString()} / ${this.maxMediaCountValue.toLocaleString()}`
    this.progressCountTarget.setAttribute('value', total)
    this.mediaUsageRateTarget.innerText = `${Math.ceil(100 * (total / this.maxMediaCountValue))}%`
  }
}
