import { Controller } from "@hotwired/stimulus"
import { singleLineChartOption, PercentageChartOption, getDateRange } from "./graph_helper"
import moment from 'moment';

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String,
    chartType: {type: String, default: ''},
    chartName: String
  }

  async connect() {
    const res = await fetch(this.endpointValue)
    const points = await res.json()

    let params = new URLSearchParams(window.location.search)

    let startDate = params.get("from_date")
    startDate ??= moment().subtract(30, 'days').format('YYYY/MM/DD')

    let endDate = params.get("to_date")
    endDate ??= new Date()

    let dateUnit = params.get("time_unit")
    dateUnit ??= 'day'
    let initLabels = getDateRange(moment(startDate, 'YYYY/MM/DD'), moment(endDate), dateUnit)
    let datasets = []
    initLabels.forEach(date => {
      let value = 0
      if(points.length > 0) {
        let idx = points[0].indexOf(date)
        value = idx >= 0 && points[1][idx] ? points[1][idx] : 0
      }
      datasets.push(value)
    })

    let options = singleLineChartOption
    if(this.chartTypeValue == 'percentage'){
      options = PercentageChartOption
    }

    const data = {
      datasets: [{
        fill: false,
        data: datasets,
        borderColor: '#757DCB',
        backgroundColor: '#757DCB',
        lineTension: 0
      }],
      labels: initLabels,
      chartName: this.chartNameValue
    }

    const event = new CustomEvent("csv", { detail: data });
    document.dispatchEvent(event);

    const ctx = this.graphAreaTarget.getContext('2d');
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }
}
