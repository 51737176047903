import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js"
import { format } from 'date-fns'
import queryString from 'query-string'
import { mergeParams, getParams } from '../tools/h_params'

require("flatpickr/dist/flatpickr.min.css")

export default class extends Controller {
  static values = {
    defaultDate: String
  }
  connect(){
    const input = this.element.querySelector('input')

    flatpickr(input, {
      locale: Japanese,
      onChange(selectedDates, _dateStr, instance) {
        if(selectedDates.length === 1) {
          const newParams = {
            target_date: format(selectedDates[0], 'yyyy-MM-dd'),
          }
          location.href = `${location.pathname}?${queryString.stringify(mergeParams(newParams, true))}`
        }
      },
      defaultDate: this.defaultDate()
    });
  }

  defaultDate() {
    const params = getParams()
    // dateFormat のデフォルトがY-m-dのためそのまま渡してOK
    if(params['target_date']) {
      return params['target_date'];
    }
    if(this.defaultDateValue) {
      return this.defaultDateValue
    }

    return new Date()
  }
}
