import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    maxMediaCount: Number,
    mediaSize: Number,
    warningRate: Number
  }

  initialize() {
    this.showWarning();
  }

  calculateMediaRate(){
    const maxMediaCount = this.maxMediaCountValue;
    const mediaSize = this.mediaSizeValue;
    let rateMedia = Math.ceil(100 * (mediaSize / maxMediaCount ));
    return rateMedia;
  }

  showWarning(){
    const rateMedia = this.calculateMediaRate();
    const warningRate = this.warningRateValue;
    const hintTrigger = document.querySelector('span.js-hint-trigger');
    hintTrigger.style.display = rateMedia > warningRate ? 'inherit' : 'none';
  }
}
