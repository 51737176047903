import { Controller } from "@hotwired/stimulus"
import queryString from 'query-string'
import { mergeParams, getParams } from '../tools/h_params'

export default class extends Controller {
  static targets = ['displayValues', 'button']

  static values = {
    unit: String
  }

  connect(){
    this.buttonTarget.innerText = this.unitJa()
  }

  handleClick() {
    this.displayValuesTarget.classList.toggle('is-hidden')
  }

  check(event) {
    const newParams = {
      time_unit: event.currentTarget.querySelector('input').value
    }
    location.href = `${location.pathname}?${queryString.stringify(mergeParams(newParams, true))}`
  }

  unitJa() {
    const unit = this.unitValue

    if(unit === 'month') {
      return '月別'
    }else if(unit === 'week') {
      return '週別'
    }else{
      return '日別'
    }
  }
}