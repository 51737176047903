import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  connect() {
    this.url = new URL(window.location)
  }

  toggleDropdown(event) {
    event.currentTarget.parentElement.classList.toggle('h-open')
  }

  wordTypeFilter(event) {
    this.url.searchParams.set('filter_type', event.currentTarget.value)
    window.location.href = this.url.toString()
  }
}
