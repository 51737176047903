import { Controller } from "@hotwired/stimulus"
import { lineChartOption, lineChartColors, getAllLabelsFromData, genderCodeToText } from "./graph_helper"
import { downloadCsvFromCharts } from "./csv_helper"
import moment from 'moment';

export default class extends Controller {
  static targets = ['mediaTypeGraphArea', 'followerTypeGraphArea', 'genderGraphArea', 'ageGraphArea', 'maleAgeGraphArea', 'femaleAgeGraphArea', 'unknowAgeGraphArea']

  static values = {
    mediaEndpoint: String,
    followTypeEndpoint: String,
    demographicEndpoint: String,
  }

  async connect() {
    this.chartsData = []
    this.createReachMediaTypeChart()
    this.createReachFollowTypeChart()
    this.createReachDemographicsChart()
  }

  async createReachMediaTypeChart(){
    const res = await fetch(this.mediaEndpointValue)
    const points = await res.json()

    this.createMediaTypeChart(points)
  }

  async createReachFollowTypeChart(){
    const res = await fetch(this.followTypeEndpointValue)
    const points = await res.json()

    this.createFollowTypeChart(points)
  }

  async createReachDemographicsChart(){
    const res = await fetch(this.demographicEndpointValue)
    const points = await res.json()

    //リーチ性別
    this.createGenderChart(points)
    //リーチ年代
    this.createAgeChart(points)
    //リーチ年代・性別 > 男性
    this.createAgeChart(points, 'M')
    //リーチ年代・性別 > 女性
    this.createAgeChart(points, 'F')
    //リーチ年代・性別 > その他
    this.createAgeChart(points, 'U')
  }

  createMediaTypeChart(rawData){
    const labels = getAllLabelsFromData(rawData)
    const data = this.parseMediaTypeData(rawData, labels)
    this.chartsData.push(data)
    this.drawMediaTypeChart(data, 'media_product_type')
  }

  createFollowTypeChart(rawData){
    const labels = getAllLabelsFromData(rawData)
    const data = this.parseMediaTypeData(rawData, labels)
    this.chartsData.push(data)
    this.drawMediaTypeChart(data, 'follow_type')
  }

  parseMediaTypeData(rawData, labels){
    let xAxis = []
    let mediaDatasets = {}
    labels.forEach(label => {
      mediaDatasets[label] = []
    })
    rawData.forEach((data, idx) => {
      xAxis.push(data.labels)
      labels.forEach(label => {
        mediaDatasets[label][idx] = 0
      })
      data.points.forEach(point => {
        let key = point.dimension_values[0]
        mediaDatasets[key][idx] = point.value
      })
    })

    let datasets = []
    labels.sort().forEach((label, idx) => {
      datasets.push({
        label: label,
        fill: false,
        data: mediaDatasets[label],
        borderColor: lineChartColors[label],
        backgroundColor: lineChartColors[label],
        lineTension: 0
      })
    })
    return {
      labels: xAxis,
      datasets: datasets
    }

  }

  drawMediaTypeChart(data, breakdownType){
    const options = lineChartOption
    let ctx
    if(breakdownType == 'media_product_type'){
      ctx = this.mediaTypeGraphAreaTarget.getContext('2d');
    }
    else if (breakdownType == 'follow_type'){
      ctx = this.followerTypeGraphAreaTarget.getContext('2d');
    }
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }

  createGenderChart(rawData){
    const data = this.parseGenderData(rawData)
    this.chartsData.push(data)
    this.drawGenderChart(data)
  }

  parseGenderData(rawData){
    let labels = [], males = [], females = [], others = []

    rawData.forEach(data => {
      labels.push(data.labels)
      let male = 0, female =0, other = 0
      data.points.forEach(point => {
        if (point.dimension_values.includes("M")){
          male += point.value
        } else if (point.dimension_values.includes("F")){
          female += point.value
        } else if (point.dimension_values.includes("U")){
          other += point.value
        }
      })
      males.push(male)
      females.push(female)
      others.push(other)
    })

    return {
      labels: labels,
      datasets: [
        {
          label: '男性',
          fill: false,
          data: males,
          borderColor: 'rgba(122, 128, 201, 1)',
          backgroundColor: 'rgba(122, 128, 201, 1)',
          lineTension: 0
        },{
          label: '女性',
          fill: false,
          data: females,
          borderColor: 'rgba(192, 64, 134, 1)',
          backgroundColor: 'rgba(192, 64, 134, 1)',
          lineTension: 0
        },{
          label: 'その他',
          fill: false,
          data: others,
          borderColor: 'rgba(214, 220, 83, 1)',
          backgroundColor: 'rgba(214, 220, 83, 1)',
          lineTension: 0
        }
      ]
    }
  }
  drawGenderChart(data){
    const options = lineChartOption
    const ctx = this.genderGraphAreaTarget.getContext('2d');
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }

  createAgeChart(rawData, gender) {
    const data = this.parseAgeData(rawData, gender)
    this.chartsData.push(data)
    this.drawAgeChart(data, gender)
  }
  parseAgeData(rawData, gender){
    let labels = []
    let ages = {}
    rawData.forEach(data => {
      labels.push(data.labels)
      data.points.forEach(point => {
        let age = point.dimension_values[1] // '13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'
        let curGender = point.dimension_values[0]
        if(gender !== undefined && curGender !== gender) {
          return
        }
        if (ages[age] == undefined) {
          ages[age] = []
        }
        if (ages[age][labels.length - 1] == undefined){
          ages[age][labels.length - 1] = 0
        }
        ages[age][labels.length - 1] += point.value
      })

    })
    let genderText = genderCodeToText(gender)
    return {
      labels: labels,
      datasets: [
        {
          label: `${genderText} 13-17`,
          fill: false,
          data: ages['13-17'],
          borderColor: 'rgba(122, 128, 201, 1)',
          backgroundColor: 'rgba(122, 128, 201, 1)',
          lineTension: 0
        },{
          label: `${genderText} 18-24`,
          fill: false,
          data: ages['18-24'],
          borderColor: 'rgba(192, 64, 134, 1)',
          backgroundColor: 'rgba(192, 64, 134, 1)',
          lineTension: 0
        },{
          label: `${genderText} 25-34`,
          fill: false,
          data: ages['25-34'],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          lineTension: 0
        },
        {
          label: `${genderText} 35-44`,
          fill: false,
          data: ages['35-44'],
          borderColor: 'rgb(255, 159, 64)',
          backgroundColor: 'rgb(255, 159, 64)',
          lineTension: 0
        },
        {
          label: `${genderText} 45-54`,
          fill: false,
          data: ages['45-54'],
          borderColor: 'rgb(255, 205, 86)',
          backgroundColor: 'rgb(255, 205, 86)',
          lineTension: 0
        },
        {
          label: `${genderText} 55-64`,
          fill: false,
          data: ages['55-64'],
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgb(75, 192, 192)',
          lineTension: 0
        },
        {
          label: `${genderText} 65+`,
          fill: false,
          data: ages['65+'],
          borderColor: 'rgb(153, 102, 255)',
          backgroundColor: 'rgb(153, 102, 255)',
          lineTension: 0
        }
      ]
    }
  }

  drawAgeChart(data, gender) {
    const options = lineChartOption
    let ctx
    if(gender == 'M'){
      ctx = this.maleAgeGraphAreaTarget.getContext('2d');
    }
    else if(gender == 'F'){
      ctx = this.femaleAgeGraphAreaTarget.getContext('2d');
    }
    else if(gender == 'U'){
      ctx = this.unknowAgeGraphAreaTarget.getContext('2d');
    }
    else {
      ctx = this.ageGraphAreaTarget.getContext('2d');
    }
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }

  downloadCsv(){
    let fileName = `user_insights_reach_${moment().format('YYYY/MM/DD HH:mm:ss')}`
    downloadCsvFromCharts(this.chartsData, fileName)
  }
}
