import { Controller } from "@hotwired/stimulus"
import queryString from 'query-string'
import { mergeParams, getParams } from '../tools/h_params'
import axios from "axios";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

export default class extends Controller {
  connect(){
  }

  async toggleConnection(event){
    const params = event.params
    const res = await axios.patch(params.updateUrl)

    if(res.data.is_complete) {
      location.reload()
    }else{
      alert(res.data.error_message.join('\n'))
    }
  }
}
