import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'select'
  ]

  connect() {
    console.log('hoge')
  }

  handlePlanChange(){
    if(window.confirm('ダウングレードの場合、データが消去されるケースがあります')) {
      this.element.submit()
    }
  }

  handleActiveChange(){
    this.element.submit()
  }
}