import { Controller } from "@hotwired/stimulus"
import { downloadCsvFromAllCharts } from "./csv_helper"
import moment from 'moment';

export default class extends Controller {
  connect() {
    this.chartsData = []
    document.addEventListener(
      "csv",
      (e) => {
        this.updateChartsData(e.detail)
      },
      false
    );

  }

  updateChartsData(value){
    const chartOrder = this.getChartOrder(value.chartName)
    this.chartsData[chartOrder] = value
    if(this.chartsData.length > 0) {
      let downloadBtn = document.getElementById('graph-csv')
      downloadBtn.classList.remove('csv-btn-disabled')
    }
  }

  downloadCsv(){
    let fileName = `user_insights_${moment().format('YYYY/MM/DD HH:mm:ss')}`
    downloadCsvFromAllCharts(this.chartsData, fileName)
  }

  getChartOrder(chartName) {
    const chartsName = ['フォロワー数', 'フォロー率', '新規フォロワー数',
                        'フォローをやめたアカウント数', 'プロフィール閲覧数',
                        'プロフィール訪問率', 'インプレッション',
                        'リーチ', 'インタラクション数', 'エンゲージしたアカウント数',
                        'いいね数', 'コメント数', '保存数', 'シェア数', '返信数', 'ウェブサイトボタンタップ数',
                        'プロフィールリンクタップ数']

    return chartsName.indexOf(chartName)
  }

}
