import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    datasets: Object
  }

  connect() {
    this.initGraph(this.dataForGraph())
  }

  dataForGraph() {
    const labels = ['男性', '女性', 'その他']
    const datasets = [0, 0, 0]

    Object.keys(this.datasetsValue).forEach((key) => {
      if(key.includes('M')){
        datasets[0] += this.datasetsValue[key]
      }else if(key.includes('F')) {
        datasets[1] += this.datasetsValue[key]
      }else if(key.includes('U')) {
        datasets[2] += this.datasetsValue[key]
      }
    })

    return [
      labels,
      datasets
    ]
  }

  initGraph(dataGroup) {
    const options = {
      indexAxis: 'y',
      scales: {
        y:
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              min: 0,
              fontColor: '#637178',
              precision: 0,
            }
          },
        x:
          {
            grid: {
              display: false,
              color: '#E3E8EB',
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 30,
              minRotation: 0,
              fontColor: '#637178',
            }
          }
      },
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend : {
          display : false
        }
      }
    }

    const data = {
      labels: dataGroup[0],
      datasets: [{
        data: dataGroup[1],
        backgroundColor: [
          "rgba(122, 128, 201, 1)",
          "rgba(192, 64, 134, 1)",
          "rgba(214, 220, 83, 1)"
        ]
      }]
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    this.chart = new Chart(
      ctx, {
      type: 'bar',
      data,
      options
    })
  }
}
