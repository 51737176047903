import store from "./store/store";
import UsersRegistrationsNewPage from "./pages/users/registrations/new_page.vue";
import UsersProfilesNewPage from "./pages/users/profiles/new_page.vue";
import UsersPasswordResetsNewPage from "./pages/users/password_resets/new_page.vue";
import UsersPasswordResetsEditPage from "./pages/users/password_resets/edit_page.vue";


import { createApp } from 'vue'
const createCommonApp = (options) => {
  const app = createApp(options)
  app.use(store)

  return app
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("js-app-users-email-confirmation")) {
    // start: 初期化処理
    const { userRegistrationUrl } = document.getElementById(
      "js-app-users-email-confirmation"
    ).dataset;
    store.commit("setUserRegistrationUrl", { userRegistrationUrl });
    // end: 初期化処理

    createCommonApp({...UsersRegistrationsNewPage})
      .mount('#js-app-users-email-confirmation')
  }

  if (document.getElementById("js-app-users-profile")) {

    const { termsUrl } = document.getElementById(
      "js-app-users-profile"
    ).dataset;
    store.commit("setTermsUrl", { termsUrl });

    createCommonApp({...UsersProfilesNewPage})
      .mount('#js-app-users-profile')
  }

  if(document.getElementById("js-app-users-password-reset-edit")) {
    createCommonApp({...UsersPasswordResetsEditPage})
      .mount('#js-app-users-password-reset-edit')

  }

  if(document.getElementById("js-app-users-password-reset-new")) {
    createCommonApp({...UsersPasswordResetsNewPage})
      .mount('#js-app-users-password-reset-new')
  }
});
