import { Controller } from "@hotwired/stimulus"
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {

  static targets = ['tabs', 'graphArea']

  connect() {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById('posts');

    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      window.lazyloadInstance.update()
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    window.lazyloadInstance = new LazyLoad({
    });
  }

  sortTable(event) {
    const sortBy = event.target.parentElement.dataset.sortBy
    const orderBy = event.target.parentElement.dataset.orderBy
    const url = new URL(window.location)
    url.searchParams.set('sort_by', sortBy)
    url.searchParams.set('order_by', orderBy)
    window.location.href = url.toString()
  }

  getIndex(el){
    return [...el.parentElement.children].indexOf(el);
  }
}
