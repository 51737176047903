<template lang="pug">
#app-users-password-reset-edit
  template(v-if="step === 1")
    h1.has-text-centered パスワード再登録
    form(@submit.prevent="submitForm")
      .field.is-horizontal.is-align-items-center
        .field-label.h-customize
          label.label
            = 'パスワード'
            .is-size-7 （半角英数記号のみ8文字以上）
        .field-body.h-customize
          .field
            input.input(
              v-model="password"
              @input="inputPassword"
              type="password"
              pattern='^[a-zA-Z0-9!-/:-@¥[-`{-~]*$'
            )
            p.help.is-danger(v-for="err in passwordErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label パスワード確認用
        .field-body.h-customize
          .field
            input.input(
              v-model="passwordConfirmation"
              @input="inputPasswordConfirmation"
              type="password"
              pattern='^[a-zA-Z0-9!-/:-@¥[-`{-~]*$'
            )
            p.help.is-danger(v-for="err in passwordConfirmationErrors") {{ err }}
      .has-text-centered
        button.button.h-customize.h-is-blue(
          :disabled="isButtonDisabled"
          :class='{"is-loading": isSubmitting}'
        ) 完了する

  template(v-if="step === 2")
    h1.has-text-centered パスワードの再登録完了
    p
      | パスワードの再登録が完了しました。
    .has-text-centered
      button.button.is-text(
        @click="redirectDashboard"
      ) サービスページへ移動する

  template(v-if="step === 0")
    h1.has-text-centered パスワードの再登録に失敗しました。
</template>

<script>
import manageParams from '../../../mixins/params';

export default {
  mixins: [manageParams],
  data() {
    return {
      email: '',
      password: null,
      passwordConfirmation: null,
      teamId: null,

      isSubmitting: false,
      step: 1,
      ott: '',

      passwordErrors: [],
      passwordConfirmationErrors: [],
    }
  },
  mounted() {
    this.email = this.getParams().email.replace(' ', '+')  // エイリアス対策
    this.ott = this.getParams().ott
  },
  computed: {
    isButtonDisabled() {
      if([this.password, this.passwordConfirmation].includes(null)){ return true }
      if(this.password.length < 8) { return true }
      if(this.password !== this.passwordConfirmation) { return true }

      return false
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isSubmitting = true
        const {is_complete, team_id } = await this.$store.dispatch('patchUserPasswordReset', {
          user: {
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
          },
          ott: {
            value: this.ott
          }
        })
        this.isSubmitting = false
        if(is_complete) {
          this.step = 2
          this.teamId = team_id
        }
      } catch(e) {
        this.step = 0
      }
    },
    redirectDashboard() {
      location.href = `/teams/${this.teamId}/instagram_accounts`
    },

    inputPassword(event) {
      this.passwordErrors = []
      this.password = event.target.value
      if(this.password === '') {
        this.passwordErrors.push('パスワードを入力してください。')
      }
    },

    inputPasswordConfirmation(event) {
      this.passwordConfirmationErrors = []
      this.passwordConfirmation = event.target.value
      if(this.passwordConfirmation === '') {
        this.passwordConfirmationErrors.push('パスワード確認欄を入力してください。')
      }

      if(this.passwordConfirmation !== this.password) {
        this.passwordConfirmationErrors.push('パスワードが一致しません。正しいパスワードを入力してください。')
      }
    },
  }
};
</script>
