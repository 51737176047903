import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.displayMenu();
    }

    displayMenu() {
        const dropdownContainer = document.querySelector('.period-dropdown-container');
        const dropdownHeader = dropdownContainer.querySelector('.period-dropdown-header');
        const radioButtons = dropdownContainer.querySelectorAll('input[type="radio"]');
        const dropdownTitle = dropdownContainer.querySelector('.dropdown-title');
        
        // Set the dropdown title to the default checked value on page load
        const defaultChecked = dropdownContainer.querySelector('input[type="radio"]:checked');
        if (defaultChecked) {
            dropdownTitle.textContent = defaultChecked.value;
        }

        // Toggle the dropdown options visibility
        dropdownHeader.addEventListener('click', function() {
            dropdownContainer.classList.toggle('active');
        });

        // Update the title when an option is selected
        radioButtons.forEach(function(radio) {
            radio.addEventListener('change', function() {
                dropdownTitle.textContent = radio.value;
                dropdownContainer.classList.remove('active'); // Close the dropdown after selection
            });
        });

        // Close the dropdown when clicking outside
        document.addEventListener('click', function(event) {
            if (!dropdownContainer.contains(event.target)) {
            dropdownContainer.classList.remove('active');
            }
        });
    }
}