import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    defaultOpen: Boolean,
    stepRegistHashtag: Boolean,
    stepRegistInstagramAccount: Boolean,
    stepInviteMember: Boolean,
    closeIconSrc: String,
    startIconSrc: String,
  }

  static targets = [
    'tutorialModal',
    'imgSrc',
    'stepRegistHashtag',
    'stepRegistInstagramAccount',
    'stepInviteMember'
  ]

  connect() {
    if(this.defaultOpenValue) {
      this.tutorialModalTarget.classList.add('open')
      this.imgSrcTarget.setAttribute('src', this.closeIconSrcValue)
    }else{
      this.tutorialModalTarget.classList.remove('open')
      this.imgSrcTarget.setAttribute('src', this.startIconSrcValue)
    }

    this.initStepCompletes()
    this.initStepPrepares()
  }

  toggleTutorial() {
    if(this.tutorialModalTarget.classList.contains('open')) {
      this.tutorialModalTarget.classList.remove('open')
      this.imgSrcTarget.setAttribute('src', this.startIconSrcValue)
    } else {
      this.tutorialModalTarget.classList.add('open')
      this.imgSrcTarget.setAttribute('src', this.closeIconSrcValue)
    }
  }

  initTutorialModal() {
  }

  initStepCompletes() {
    if(this.stepRegistHashtagValue) {
      this.stepRegistHashtagTarget.classList.add('done')
    }
    if(this.stepRegistInstagramAccountValue) {
      this.stepRegistInstagramAccountTarget.classList.add('done')
    }
    if(this.stepInviteMemberValue) {
      this.stepInviteMemberTarget.classList.add('done')
    }
  }

  // 仕様: https://www.notion.so/HashPick-931564f995374a6dbab1152e0aa789b1?p=db1763deff1d4858a83636b0ed87299a&pm=c
  initStepPrepares() {
    const steps = [
      this.stepInviteMemberValue,
      this.stepRegistInstagramAccountValue,
      this.stepRegistHashtagValue
    ]

    const stepTargets = [
      this.stepInviteMemberTarget,
      this.stepRegistInstagramAccountTarget,
      this.stepRegistHashtagTarget
    ]

    console.log(steps)

    const idx = steps.lastIndexOf(true)
    if(idx === -1) {
      // ユーザー招待はスキップ可能なので0の時は 2
      stepTargets.slice(2).forEach((elem) => elem.classList.add('prepare'))
    } else if(idx === 0) {
      stepTargets.slice(2).forEach((elem) => elem.classList.add('prepare'))
    } else if(idx === 1) {
      stepTargets.slice(3).forEach((elem) => elem.classList.add('prepare'))
    }
  }
}
