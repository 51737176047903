import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String
  }

  async connect() {
    const res = await fetch(this.endpointValue)
    const points = await res.json()

    const options = {
      scales: {
        y:
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              min: 0,
              fontColor: '#637178',
              precision: 0,
              callback: function(label, _index, _labels) {
                return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }
          },
        x:
          {
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 30,
              minRotation: 0,
              fontColor: '#637178',
            }
          }
      },
      elements: {
        point:{
          hitRadius: 10,
          hoverRadius: 4
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend : {
          display : false
        }
      }
    }

    const data = {
      datasets: [{
        fill: false,
        data: points[1],
        borderColor: '#757DCB',
      backgroundColor: '#757DCB',
        lineTension: 0
      }],
      labels: points[0]
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }
}
