import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitBtn']
  
  connect() {
    this.password = ''
    this.passwordConfirmation = ''
  }

  handlePassword(event) {
    this.password = event.currentTarget.value
    this.handleInput()
  }

  handlePasswordConfirmation(event) {
    this.passwordConfirmation = event.currentTarget.value
    this.handleInput()
  }

  handleInput() {
    let isDisabled = false

    if(this.password.length < 8) { isDisabled = true }
    if(this.passwordConfirmation.length < 8) { isDisabled = true }
    if(this.password !== this.passwordConfirmation) { isDisabled = true }

    this.submitBtnTarget.disabled = isDisabled
  }
}
