import { Controller } from "@hotwired/stimulus"

import axios from "axios";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

export default class extends Controller {
  static values = {
    url: String
  }

  connect(){
  }

  async click(event){
    event.currentTarget.classList.add('is-loading')

    await awios.get(this.urlValue)

    event.currentTarget.classList.remove('is-loading')
  }
}
