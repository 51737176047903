import { Controller } from "@hotwired/stimulus"
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  static targets = [ "modal", "input", "name", "errors", "send" ]

  connect() {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById('posts');

    if(targetNode === null) return;
    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      window.lazyloadInstance.update()
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    window.lazyloadInstance = new LazyLoad({
    });
  }

  showModal() {
    this.modalTarget.classList.add('is-active')
  }

  closeModal() {
    this.modalTarget.classList.remove('is-active')
  }

  checkValidation() {
    const errors = this.checkTag(this.inputTarget.value, [])
    this.errorsTarget.textContent = errors.join('\n')
    if(errors.length > 0) {
      this.sendTarget.disabled = true
    }else{
      this.sendTarget.disabled = false
    }
  }

  checkTag(value, errors = []) {
    return [
      ...this.tagMustBeFull(value, errors),
      ...this.tagMustBeUniq(value, errors),
      ...this.tagMustBeValidFormat(value, errors)
    ]
  }

  tagMustBeFull(value, errors = []) {
    let errs = []
    Object.assign(errs, errors)
    if(value === '') {
      errs.push('ハッシュタグを入力してください。')
    }
    return errs
  }

  tagMustBeUniq(value, errors = []) {
    let errs = []
    Object.assign(errs, errors)
    const names = this.nameTargets.map((item) => item.textContent)
    if(names.includes(value)) {
      errs.push('同じハッシュタグが含まれています。')
    }
    return errs
  }

  tagMustBeValidFormat(value, errors = []) {
    let errs = []
    Object.assign(errs, errors)
    // TODO: 正規表現へ
    if(value && value.includes(' ')) { errs.push('半角スペースが含まれています。') }
    if(value && value.includes('　')) { errs.push('全角スペースが含まれています。') }
    if(value && value.includes('$')) { errs.push('$が含まれています。') }
    if(value && value.includes('%')) { errs.push('%が含まれています。') }
    return errs
  }

  sortTable(event) {
    const sortBy = event.target.parentElement.dataset.sortBy
    const orderBy = event.target.parentElement.dataset.orderBy
    const url = new URL(window.location)
    url.searchParams.set('sort_by', sortBy)
    url.searchParams.set('order_by', orderBy)
    window.location.href = url.toString()
  }
}
