import { Controller } from "@hotwired/stimulus"
import { lineChartOption, lineChartColors, getAllLabelsFromData } from "./graph_helper"
import { downloadCsvFromCharts } from "./csv_helper"
import moment from 'moment';

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String,
    metricType: String,
  }

  async connect() {
    this.chartData = {}
    this.chartLabels = []
    const res = await fetch(this.endpointValue)
    const points = await res.json()

    if(this.metricTypeValue == 'media_product_type'){
      this.chartLabels = getAllLabelsFromData(points)
      this.createMediaTypeChart(points)
    }
    else if (this.metricTypeValue == 'follow_type') {
      this.chartLabels = getAllLabelsFromData(points)
      this.createFollowTypeChart(points)
    }
    else if (this.metricTypeValue == 'contact_button_type') {
      this.chartLabels = getAllLabelsFromData(points)
      this.createContactButtonTypeChart(points)
    }
  }

  createMediaTypeChart(rawData){
    this.chartData = this.parseMediaTypeData(rawData, this.chartLabels)
    this.drawMediaTypeChart(this.chartData)
  }

  createFollowTypeChart(rawData){
    this.chartData = this.parseMediaTypeData(rawData, this.chartLabels)
    this.drawMediaTypeChart(this.chartData)
  }

  createContactButtonTypeChart(rawData){
    this.chartData = this.parseMediaTypeData(rawData, this.chartLabels)
    this.drawMediaTypeChart(this.chartData)
  }

  parseMediaTypeData(rawData, labels){
    let xAxis = []
    let mediaDatasets = {}
    labels.forEach(label => {
      mediaDatasets[label] = []
    })
    rawData.forEach((data, idx) => {
      xAxis.push(data.labels)
      labels.forEach(label => {
        mediaDatasets[label][idx] = 0
      })
      data.points.forEach(point => {
        let key = point.dimension_values[0]
        mediaDatasets[key][idx] = point.value
      })
    })

    let datasets = []
    labels.sort().forEach((label, idx) => {
      datasets.push({
        label: label,
        fill: false,
        data: mediaDatasets[label],
        borderColor: lineChartColors[label],
        backgroundColor: lineChartColors[label],
        lineTension: 0
      })
    })
    return {
      labels: xAxis,
      datasets: datasets
    }

  }

  drawMediaTypeChart(data){
    const options = lineChartOption
    let ctx = this.graphAreaTarget.getContext('2d');
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }


  downloadCsv(){
    let pageName = new URLSearchParams(window.location.search).get('breakdown_type')
    let fileName = `user_insights_${pageName}_${moment().format('YYYY/MM/DD HH:mm:ss')}`
    downloadCsvFromCharts([this.chartData], fileName)
  }
}
