import queryString from "query-string";
import { match } from "path-to-regexp";

const routing = [
  '/users/:userId',
  '/users/:userId/instagram_accounts/:instagramAccountId',
  '/teams/:teamId',
  '/teams/:teamId/instagram_accounts/:instagramAccountId'
]

export const getParams = () => {
  const urlParams = getPathParams()
  const queryParams = getQueryParams()

  return {...urlParams, ...queryParams}
}

export const getPathParams = () => {
  const urlParamArray = routing.map((url) => {
    const fn = match(url, { end: false })
    const res = fn(location.pathname)
    return res.params
  })
  const reducer = (accumulator, currentValue) => { return {...accumulator, ...currentValue}};

  return urlParamArray.reduce(reducer, {})
}

export const getQueryParams = () => {
  return queryString.parse(location.search, {arrayFormat: 'bracket'});
}

export const mergeParams = (newParams = {}, resetOffset=false) => {
  const qParams = getQueryParams()
  if(resetOffset) {
    delete qParams.offset
  }
  return {...qParams, ...newParams}
}
