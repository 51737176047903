import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "modalTitle", "modalBody" ]

  openModal(event){
    const instagramURL = 'https://www.instagram.com/explore/tags/'
    this.modalTarget.classList.add('is-active')

    const description = JSON.parse(event.currentTarget.dataset.head)
    let html = ''
    description.forEach((item) => {
      html += `<tr><td><a href="${instagramURL + item[0]}" target="_blank">${item[0]}</a></td><td>${item[1]}</td></td>`
    })
    this.modalBodyTarget.innerHTML = html

    const keyword = event.currentTarget.dataset.keyword
    this.modalTitleTarget.innerText = `キーワード: ${keyword}`
  }
  closeModal(){
    this.modalTarget.classList.remove('is-active')
  }
}
