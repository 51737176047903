import { Controller } from "@hotwired/stimulus"
import { getAgeIndex, getSexIndex, horizontalBarChartOption } from "./graph_helper"

export default class extends Controller {
  static targets = ['genderGraphArea', 'ageGraphArea']

  static values = {
    datasets: []
  }

  connect() {
    // リーチ男女比
    this.createGenderChart()
    // リーチ年齢層
    this.createAgeChart()
  }

  createAgeChart() {
    const data = this.parseAgeData()
    this.drawAgeGraph(data)
  }

  parseAgeData(){
    const labels = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']
    const datasets = [
      [0, 0, 0, 0, 0, 0, 0], // 男
      [0, 0, 0, 0, 0, 0, 0], // 女
      [0, 0, 0, 0, 0, 0, 0], // その他
    ]

    this.datasetsValue.forEach((dataset) => {
      const age = dataset.age
      const sex = dataset.gender

      const ageIndex = getAgeIndex(age)
      const sexIndex = getSexIndex(sex)

      datasets[sexIndex][ageIndex] += dataset.val
    })

    return {
      labels: labels,
      datasets: [{
        label: '男',
        data: datasets[0],
        backgroundColor: "rgba(122, 128, 201, 1)"
      },{
        label: '女',
        data: datasets[1],
        backgroundColor: "rgba(192, 64, 134, 1)"
      },{
        label: 'その他',
        data: datasets[2],
        backgroundColor: "rgba(214, 220, 83, 1)"
      }]
    }
  }

  drawAgeGraph(data) {
    const options = horizontalBarChartOption

    const ctx = this.ageGraphAreaTarget.getContext('2d');
    ctx.canvas.height = 256;
    this.chart = new Chart(
      ctx, {
      type: 'bar',
      data,
      options
    })
  }

  createGenderChart(){
    const data = this.parseGenderData()
    this.drawGenderGraph(data)
  }
  parseGenderData(){
    const labels = ['男性', '女性', 'その他']
    const datasets = [0, 0, 0]

    this.datasetsValue.forEach((dataset) => {
      const sex = dataset.gender
      const sexIndex = getSexIndex(sex)
      datasets[sexIndex] += dataset.val

    })

    return {
      labels: labels,
      datasets: [{
        data: datasets,
        backgroundColor: [
          "rgba(122, 128, 201, 1)",
          "rgba(192, 64, 134, 1)",
          "rgba(214, 220, 83, 1)"
        ]
      }]
    }
  }
  drawGenderGraph(data){
    const options = horizontalBarChartOption

    const ctx = this.genderGraphAreaTarget.getContext('2d');
    ctx.canvas.height = 130;
    this.chart = new Chart(
      ctx, {
      type: 'bar',
      data,
      options
    })
  }
}

