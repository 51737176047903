import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  click(){
    const dataLayer = window.dataLayer || []

    const event = this.element.dataset.dlevent
    const eventName = this.element.dataset.dleventName
    const eventParams = this.element.dataset.dleventParams

    const dataLayerItem = {}

    dataLayerItem['event'] = event
    dataLayerItem['event_name'] = eventName

    if(eventParams) {
      const params = JSON.parse(eventParams)
      dataLayerItem['event_params'] = params
    }

    dataLayer.push(dataLayerItem)
  }
}