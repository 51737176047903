import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    datasets: Object
  }

  connect() {
    this.initGraph(this.dataForGraph())
  }

  dataForGraph() {
    const labels = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']
    const datasets = [
      [0, 0, 0, 0, 0, 0, 0], // 男
      [0, 0, 0, 0, 0, 0, 0], // 女
      [0, 0, 0, 0, 0, 0, 0], // その他
    ]

    Object.keys(this.datasetsValue).forEach((key) => {
      const age = key.split('.')[1]
      const sex = key.split('.')[0]

      const ageIndex = this.getAgeIndex(age)
      const sexIndex = this.getSexIndex(sex)

      datasets[sexIndex][ageIndex] += this.datasetsValue[key]
    })

    return [
      labels,
      datasets
    ]
  }

  initGraph(dataGroup) {
    const options = {
      // horizontalBar
      indexAxis: 'y',
      scales: {
        y:
          {
            ticks: {
              beginAtZero: true,
              fontColor: '#637178',
              precision: 0,
            },
            stacked: true
          },
        x:
          {
            grid: {
              display: true,
              color: '#E3E8EB',
            },
            ticks: {
              min: 0,
              fontColor: '#637178',
            },
            stacked: true
          }
      },
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend : {
          display : false
        }
      }
    }

    const data = {
      labels: dataGroup[0],
      datasets: [{
        label: '男',
        data: dataGroup[1][0],
        backgroundColor: "rgba(122, 128, 201, 1)"
      },{
        label: '女',
        data: dataGroup[1][1],
        backgroundColor: "rgba(192, 64, 134, 1)"
      },{
        label: 'その他',
        data: dataGroup[1][2],
        backgroundColor: "rgba(214, 220, 83, 1)"
      }]
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    ctx.canvas.height = 256;
    this.chart = new Chart(
      ctx, {
      type: 'bar',
      data,
      options
    })
  }

  getAgeIndex(age) {
    switch(age) {
      case '13-17':
        return 0
      case '18-24':
        return 1
      case '25-34':
        return 2
      case '35-44':
        return 3
      case '45-54':
        return 4
      case '55-64':
        return 5
      case '65+':
        return 6
    }
  }

  getSexIndex(sex) {
    switch(sex) {
      case 'M':
        return 0
      case 'F':
        return 1
      case 'U':
        return 2
    }
  }
}

