import axios from "axios";
import { createStore as _createStore } from 'vuex'


axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

const store = _createStore({
  state: {
    userRegistrationUrl: "",
    termsUrl: ""
  },
  getters: {},
  mutations: {
    setUserRegistrationUrl(state, payload) {
      state.userRegistrationUrl = payload.userRegistrationUrl; // eslint-disable-line no-param-reassign
    },
    setTermsUrl(state, payload) {
      state.termsUrl = payload.termsUrl; // eslint-disable-line no-param-reassign
    },
  },
  actions: {
    async sentRegistrationEmail({}, payload) {
      const res = await axios.post("/users/registrations", payload)
      return res.data.is_sent
    },
    async sentPasswordResetEmail({}, payload) {
      const res = await axios.post('/users/password-resets', {
        user: {
          email: payload.email
        }
      })
      return res.data.is_sent
    },
    async patchUserProfile({}, payload) {
      const res = await axios.patch("/users/profiles", {
        user: payload.user,
        team: payload.team,
        ott: payload.ott
      })
      return {is_complete: res.data.complete_profile, team_id: res.data.team_id}
    },
    async patchUserPasswordReset({}, payload) {
      const res = await axios.patch("/users/password-resets", {
        user: payload.user,
        ott: payload.ott
      })
      return {is_complete: res.data.is_completed, team_id: res.data.team_id}
    },
    async checkTeamCodeUniq({}, payload) {
      const res = await axios.post("/users/registrations/check_team_code_uniq", {
          team: payload
      })
      return res.data.is_exists
    }
  },
});

export default store;
