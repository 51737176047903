import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['displayModal']

  connect() {
    this.url = new URL(window.location)
    this.element.addEventListener('blur', (event) => {
      this.displayModalTarget.classList.remove('h-open')
    });
  }

  tagFilter(event) {
    this.url.searchParams.set('query', event.currentTarget.value)
    window.location.href = this.url.toString()
  }

  postTermFilter(event) {
    this.url.searchParams.set('posts_term_filter', event.currentTarget.value)
    window.location.href = this.url.toString()
  }

  toggleDropdown(event) {
    event.currentTarget.classList.toggle('h-open')
  }
}