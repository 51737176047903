import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.addDeleteListener();
  }

  addDeleteListener() {
    const deleteIcons = this.element;
    deleteIcons.addEventListener('click', this.handleDeleteElement.bind(this));
  }

  handleDeleteElement() {
    const keywordSearchElements = document.querySelectorAll('.keyword-search');
    const dateRangeElements = document.querySelectorAll('.daterange-picker');

    const keywordElementDel = this.element.closest('.keyword-search');
    const dateRangeElementDel = this.element.closest('.daterange-picker');

    let indexDel = 0;
    if (keywordElementDel) indexDel = Array.prototype.indexOf.call(keywordSearchElements, keywordElementDel);
    else if (dateRangeElementDel) indexDel = Array.prototype.indexOf.call(dateRangeElements, dateRangeElementDel);

    if (keywordSearchElements.length >= 3 || dateRangeElements.length >= 3) {
      document.querySelector('.add-keywords').style.display = 'block';
      document.querySelector('.add-daterange').style.display = 'block';
    }
    if (keywordSearchElements.length <= 2 || dateRangeElements.length <= 2) {
      const deleteElement = document.querySelectorAll('[class^="delete-icon"]');
      deleteElement.forEach(element => {
        element.style.display = 'none';
      });
    }

    console.log(indexDel);
    console.log(keywordSearchElements);
    console.log(dateRangeElements);

    keywordSearchElements[indexDel].remove();
    dateRangeElements[indexDel].remove();
  }
}
