import queryString from "query-string";

const manageParams = { 
  methods: {
    getParams() {
      return queryString.parse(location.search, {arrayFormat: 'bracket'});
    },
    mergeParams(newParams = {}) {
      return {...this.getParams(), ...newParams}
    }
  },
};

export default manageParams;