import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.displayMenu();
    }

    displayMenu() {
        let downloadButton=document.querySelector('.circle-button[data-toggle="dropdown"]');
        const dropdownContainer = downloadButton.querySelector('.period-dropdown-menu');
        
        downloadButton.addEventListener("click", (event) => {
            dropdownContainer.classList.toggle('active-download');
        })

        document.addEventListener('click', function(event) {
            if (!downloadButton.contains(event.target)) {
                dropdownContainer.classList.remove('active-download');
            }
        })
    }
}