import { Controller } from "@hotwired/stimulus"
import axios from "axios";
import moment from 'moment';

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

export default class extends Controller {
  static targets = [
    'modal',
    'send',
    'input',
    'messageArea',
    'recipientMessage',
    'senderMessage'
  ]

  static values = {
    messageEndpoint: String
  }

  connect(){
    console.log(this.messageEndpointValue)
  }

  initialize() {
    this.inputTarget.value = ''
    this.messageAreaHTML = ''
  }

  inputHandler(event) {
    if(event.currentTarget.value) {
      this.sendTarget.disabled = false
    }else{
      this.sendTarget.disabled = true
    }
  }

  async showModal({params}) {
    this.modalTarget.classList.add('is-active')
    this.senderId = params.senderId

    const endpoint = this.messageEndpointValue.replace('SENDER_ID', this.senderId)

    const res = await axios.get(endpoint)

    this.showMessage(res.data)
  }

  async sendMessage(event){
    const btn = event.currentTarget
    btn.classList.add('is-loading')
    const endpoint = this.messageEndpointValue.replace('SENDER_ID', this.senderId)
    const res = await axios.post(endpoint, {sender_id: this.senderId, text: this.inputTarget.value})
    btn.classList.remove('is-loading')
    this.closeModal()

    if(res.status === 200) {
      window.toastr.success('送信に成功しました。')
    }else{
      window.toastr.error('送信に失敗しました。')
    }
  }

  closeModal() {
    this.modalTarget.classList.remove('is-active')
    this.senderId = null
    this.initialize()
  }

  showMessage(messages) {
    const recipientFormat = this.recipientMessageTarget.innerHTML
    const senderFormat = this.senderMessageTarget.innerHTML

    const messageAreaHTML = messages.map((message) => {
      if(this.senderId.toString() === message.sender_id){
        // 受信したメッセージ
        return this.replaceFormat(message, recipientFormat)
      }else{
        // 送信したメッセージ
        return this.replaceFormat(message, senderFormat)
      }
    })

    this.messageAreaTarget.innerHTML = messageAreaHTML.join('')
  }

  replaceFormat(message, format) {
    let html = ''
    switch(message.message_type){
      case 'text':
          html = format.replace('MESSAGE', message.text).replace('TIME', moment(message.timestamp).format('YYYY/MM/DD HH:mm:ss'))
          break;
      case 'image':
      case 'video':
      case 'file':
      case 'audio':
      case 'story_mention':
          html = format.replace('MESSAGE', `${message.message_type}のメッセージ`).replace('TIME', moment(message.timestamp).format('YYYY/MM/DD HH:mm:ss'))
          break;
    }

    return html
  }
}
