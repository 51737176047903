import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal', 'submitBtn']

  connect() {
    this.email = this.element.dataset.email
  }

  openModal() {
    this.modalTarget.classList.add('is-active')
  }

  closeModal() {
    this.modalTarget.classList.remove('is-active')
  }

  handleEmail(event) {
    this.email = event.currentTarget.value
    this.handleInput()
  }

  handleInput() {
    this.submitBtnTarget.disabled = !this.email.includes('@')
  }
}
