import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitBtn']

  connect() {
    this.email = this.element.dataset.email
    this.emailConfirmation = ''
  }

  handleEmail(event) {
    this.email = event.currentTarget.value
    this.handleInput()
  }

  handleEmailConfirmation(event) {
    this.emailConfirmation = event.currentTarget.value
    this.handleInput()
  }

  handleInput() {
    let isDisabled = false

    if(!this.email.includes('@')) { isDisabled = true }
    if(!this.emailConfirmation.includes('@')) { isDisabled = true }
    if(this.email !== this.emailConfirmation) { isDisabled = true }

    this.submitBtnTarget.disabled = isDisabled
  }
}
