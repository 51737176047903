import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  sortTable(event) {
    const sortBy = event.currentTarget.dataset.sortBy
    const orderBy = event.currentTarget.dataset.orderBy
    const url = new URL(window.location)
    url.searchParams.set('sort_by', sortBy)
    url.searchParams.set('order_by', orderBy)
    window.location.href = url.toString()
  }
}