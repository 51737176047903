import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    currentInstagramAccountId: String,
    activeInstagramAccounts: Object,
    teamId: String,
  }

  static targets = [
    'selectedInstagramAccountImageSrc',
    'dropdownArea',
    'accountsDisplayArea',
    'accountDisplayTemplate'
  ]

  connect() {
    if(this.currentInstagramAccountIdValue === ''){ return }

    this.currentInputKeyword = ''
    this.setSelectedInstagramAccountImageSrc()
    this.renderInstagramAccounts()
  }

  renderInstagramAccounts() {
    this.resetInstagramAccountsDisplayArea()

    let accounts;
    if(this.currentInputKeyword){
      accounts = this.activeInstagramAccountsValue.data.filter((account) => {
        return (account.attributes.username + account.attributes.display_name).includes(this.currentInputKeyword)
      })
    } else {
      accounts = this.activeInstagramAccountsValue.data
    }

    accounts.forEach((account) => {
      const clone = document.importNode(this.accountDisplayTemplateTarget.content, true)
      clone.querySelector('.dropdown-item').setAttribute('href', account.attributes.switch_url)
      clone.querySelector('.dropdown-item--img').setAttribute('src', account.attributes.picture_url)
      clone.querySelector('.dropdown-item--name').innerText = account.attributes.username
      this.accountsDisplayAreaTarget.appendChild(clone)
    })
  }

  setSelectedInstagramAccountImageSrc() {
    const selectedOne = this.activeInstagramAccountsValue.data.find((account) => account.id === this.currentInstagramAccountIdValue)
    this.selectedInstagramAccountImageSrcTarget.setAttribute('src', selectedOne.attributes.picture_url)
  }

  resetInstagramAccountsDisplayArea() {
    while(this.accountsDisplayAreaTarget.firstChild){
      this.accountsDisplayAreaTarget.removeChild(this.accountsDisplayAreaTarget.firstChild);
    }
  }

  // action
  toggleDropdown() {
    if(this.dropdownAreaTarget.classList.contains('is-active')){
      this.dropdownAreaTarget.classList.remove('is-active')
    }else{
      this.dropdownAreaTarget.classList.add('is-active')
    }
  }

  inputKeyword(event) {
    this.currentInputKeyword = event.currentTarget.value
    this.renderInstagramAccounts()
  }
}