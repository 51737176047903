import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import axios from "axios"
import { getParams } from '../tools/h_params'
import { format, subDays } from 'date-fns'

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};
export default class extends Controller {
  static chartCanvas

  static targets = ['graphArea', 'displayModal', 'displayTime', 'displayCalculate', 'displayMetric']
  static values = {
    endpoint: String,
    postType: String,
    metricType: String,
    metric: String,
    chartLabel: String,
    chartData: {type: Array, default: []},
    selectedTime: {type: String, default: 'day'},
    selectedCalculate: String,
    selectedMetric: String,
    fromDate: String,
    toDate: String
  }

  async connect() {
    this.setDefaultDate()
    const res = await axios.get(this.postInsightEndpoint())
    let initLabels = this.getDateArray(this.fromDateValue, this.toDateValue, this.selectedTimeValue)
    let initValues = this.prepareChartDataset(initLabels, res.data)
    this.updateDownloadGraphCsvLink()
    this.renderLineChart(initLabels, initValues)
  }

  postInsightEndpoint(){
    return this.endpointValue + `/?from_date=${this.fromDateValue}&to_date=${this.toDateValue}&media_product_type=${this.postTypeValue}&metric_type=${this.metricTypeValue}&display_time=${this.selectedTimeValue}&calculation_type=${this.selectedCalculateValue}`
  }

  handleTimeSelection(event){
    this.displayTimeTarget.innerHTML = event.target.parentElement.lastChild.textContent
    this.selectedTimeValue = event.target.value
    this.closeDropDown()
    this.updateDownloadGraphCsvLink()
    this.reDrawChart()
  }

  handleCalculateSelection(event){
    this.displayCalculateTarget.innerHTML = event.target.parentElement.lastChild.textContent
    this.selectedCalculateValue = event.target.value
    this.closeDropDown()
    this.updateDownloadGraphCsvLink()
    this.reDrawChart()
  }

  handleMetricSelection(event){
    this.displayMetricTarget.innerHTML = event.target.parentElement.lastChild.textContent
    this.chartLabelValue = event.target.parentElement.lastChild.textContent
    this.metricTypeValue = event.target.value
    this.closeDropDown()
    this.updateDownloadGraphCsvLink()
    this.reDrawChart()
  }

  async reDrawChart(){
    const res = await axios.get(this.postInsightEndpoint())
    this.chartCanvas.destroy()
    const labels = this.getDateArray(this.fromDateValue, this.toDateValue, this.selectedTimeValue)
    const values = this.prepareChartDataset(labels, res.data)
    this.renderLineChart(labels, values)
  }

  renderLineChart(labels, values) {
    const datasets = []

    datasets.push({
      label: this.chartLabelValue,
      fill: false,
      data: values,
      borderColor: '#757DCB',
      backgroundColor: '#757DCB',
      lineTension: 0,
    })

    const data = {
      labels,
      datasets
    }

    let tooltips = {}
    let yAxesTick = {beginAtZero: true}
    if(this.isMetricRate()){
      tooltips = {
        callbacks: {
          label: function(tooltipItem, data) {
            return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      }
      yAxesTick = {
        min: 0,
        max: 100,// Your absolute max value
        callback: function (value) {
          return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
        },
      }
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y:
          {
            ticks: yAxesTick
          },
        x:
        {
          display: true,
          scaleLabel: {
              display: true,
              labelString: '投稿日'
          }
        },
      },
      tooltip: tooltips,
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    ctx.canvas.height = 350;
    this.chartCanvas = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
    this.chartCanvas.canvas.parentNode.style.height = '350px';
  }

  getDateArray (start, end, dateUnit){
    if (dateUnit == 'day'){
      for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
      }
      arr = arr.map((v)=>v.toISOString().slice(0,10))
    }
    else if(dateUnit == 'week'){
      arr = this.getMondayDates(new Date(start), new Date(end));
      arr = arr.map((v)=>v.toISOString().slice(0,10))
    }
    else if(dateUnit == 'month') {
      arr = this.getMonths(new Date(start), new Date(end));
    }

    return arr;
  }

  getMondayDates(startDate, endDate) {
    // Create a new Date object set to the start of the week (Monday)
    const start = new Date(startDate.getTime());
    start.setDate(start.getDate() - start.getDay() + 1);

    // Create a new Date object set to the end of the week (Sunday)
    const end = new Date(endDate.getTime());
    end.setDate(end.getDate() - end.getDay() + 7);

    // Create an empty array to store the dates
    const dates = [];

    // Use a loop to iterate over the range of dates
    for (let current = start; current <= end; current.setDate(current.getDate() + 7)) {
      // Check if the current date falls on a Monday
      if (current.getDay() === 1) {
        // If it does, add it to the array
        dates.push(new Date(current.getTime()));
      }
    }

    // Return the array of dates
    return dates;
  }

  getMonths(startDate, endDate) {
    // Create a new Date object set to the start of the month
    const start = new Date(startDate.getTime());
    start.setDate(1);

    // Create a new Date object set to the end of the month
    const end = new Date(endDate.getTime());
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);

    // Create an empty array to store the months
    const months = [];

    // Use a loop to iterate over the range of dates
    for (let current = start; current <= end; current.setMonth(current.getMonth() + 1)) {
      // Add the month to the array
      months.push(current.toISOString().slice(0,7));
    }

    // Return the array of months
    return months;
  }

  prepareChartDataset(labels, currentChartData){
    let ret = []
    labels.forEach(date => {
      ret.push(currentChartData[date] ? currentChartData[date] : 0)
    });

    return ret;
  }

  setDefaultDate() {
    const params = getParams()
    // dateFormat のデフォルトがY-m-dのためそのまま渡してOK
    if(params['from_date'] && params['to_date']) {
      this.fromDateValue = params['from_date']
      this.toDateValue = params['to_date']
    }
    else {
      this.fromDateValue = format(subDays(new Date(), 30), 'yyyy-MM-dd')
      this.toDateValue = format(new Date(), 'yyyy-MM-dd')
    }
  }

  isMetricRate(){
    return [
      "like_count_rate",
      "comments_count_rate",
      "saved_rate",
      "engagement_rate",
      "shares_rate",
      "plays_rate",
      "exits_rate",
      "replies_rate",
      "total_interactions_rate",
      "completed_plays_rate"
    ].includes(this.metricTypeValue)
  }

  updateDownloadGraphCsvLink() {
    let downloadBtn = document.getElementById('graph-csv')
    downloadBtn.classList.remove('csv-btn-disabled')
    downloadBtn.href = `${this.postInsightEndpoint()}&format=csv`
  }

  closeDropDown(){
    document.querySelectorAll('.dropdown-check-list').forEach(item => {
      item.classList.remove('visible')
    })
  }

}
