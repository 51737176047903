import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3"
import cloud  from "d3-cloud"

export default class extends Controller {

  static values = {
    targetElementId: String,
    wordData: {type: Object, default: {}},
  }

  connect() {
    var TARGET_ELEMENT_ID = this.targetElementIdValue; // 描画先
    var h = 300;
    var w = 500;
    const MAX_SIZE = Object.keys(this.wordDataValue).reduce((a, v) => Math.max(a, this.wordDataValue[v]), -Infinity);

    var words = Object.keys(this.wordDataValue).map(item => {
      return {text: item, size: this.calculateFontSizebyRate(this.wordDataValue[item], MAX_SIZE)};
    })

    cloud().size([w, h])
      .words(words)
      .rotate(0)
      .font("Impact")
      .fontSize(function(d) { return d.size; })
      .on("end", draw) //描画関数の読み込み
      .start();

    // wordcloud 描画
    function draw(words) {
      d3.select(TARGET_ELEMENT_ID)
        .append("svg")
          .attr("class", "ui fluid image") // style using semantic ui
          .attr("viewBox", "0 0 " + w + " " + h )  // ViewBox : x, y, width, height
          .attr("width", "100%")    // 表示サイズの設定
          .attr("height", "100%")   // 表示サイズの設定
        .append("g")
          .attr("transform", "translate(" + w / 2 + "," + h / 2 + ")")
        .selectAll("text")
          .data(words)
        .enter().append("text")
          .style("font-size", function(d) { return d.size + "px"; })
          .style("font-family", "Impact")
          .style("fill", function(d, i) { return d3.schemeCategory10[i % 10]; })
          .attr("text-anchor", "middle")
          .attr("transform", function(d) {
            return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
          })
          .text(function(d) { return d.text; });
    }

  }

  calculateFontSizebyRate(size, max_size){
    return Math.max( (size * 100 / max_size), 16)
  }

}
