<template lang="pug">
#app-email-confirmation
  template(v-if="step === 1")
    h1.has-text-centered チーム作成
    p.has-text-centered-tablet
      | 入力したアドレスにチーム作成と会員登録のメールをお送りします。
      br
      | 添付されている URL より、本登録を行ってください
      br
      | ※ URLの有効期限は配信後1時間以内です。
    form(@submit.prevent="submitForm")
      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label メールアドレス
        .field-body.h-customize
          .field
            input.input(
              placeholder="hashpick@hottolink.com"
              @input="inputEmail"
              :value="email"
              name="user[email]"
            )
            p.help.is-danger(v-for="err in emailErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label
            | メールアドレス
            br
            | 確認用
        .field-body.h-customize
          .field
            input.input(
              placeholder="hashpick@hottolink.com"
              @input="inputEmailConfirmation"
              :value="emailConfirmation"
              name="user[email_confirmation]"
            )
            p.help.is-danger(v-for="err in emailConfirmationErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label チーム名
        .field-body.h-customize
          .field
            input.input(
              :value="teamName"
              @input="inputTeamName"
              @blur="onBlurTeamName"
              placeholder="SNSマーケティング支援事業部"
              name="team[name]"
            )
            p.help.is-danger(v-for="err in teamNameErrors") {{ err }}
      
      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label チームコード
        .field-body.h-customize
          .field
            input.input(
              :value="teamCode"
              @input="inputTeamCode"
              @blur="onBlurTeamCode"
              placeholder="チームコード"
              name="team[code]"
            )
            p.help.is-danger(v-for="err in teamCodeErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label 名前
        .field-body.h-customize
          .field
            input.input(
              :value="lastName"
              @input="inputLastName"
              placeholder="田中"
              name="user[last_name]"
            )
            p.help.is-danger(v-for="err in lastNameErrors") {{ err }}
          .field
            input.input(
              :value="firstName"
              @input="inputFirstName"
              placeholder="太郎"
              name="user[first_name]"
            )
            p.help.is-danger(v-for="err in firstNameErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label ふりがな
        .field-body.h-customize
          .field
            input.input(
              :value="kanaLastName"
              @input="inputKanaLastName"
              placeholder="たなか"
              name="user[kana_last_name]"
            )
            p.help.is-danger(v-for="err in kanaLastNameErrors") {{ err }}
          .field
            input.input(
              :value="kanaFirstName"
              @input="inputKanaFirstName"
              placeholder="たろう"
              name="user[kana_first_name]"
            )
            p.help.is-danger(v-for="err in kanaFirstNameErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label 企業名
        .field-body.h-customize
          .field
            input.input(
              :value="companyName"
              @input="inputCompanyName"
              placeholder="株式会社ホットリンク"
              name="user[company_name]"
            )
            p.help.is-danger(v-for="err in companyNameErrors") {{ err }}

      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label.label 電話番号
        .field-body.h-customize
          .field
            input.input(
              :value="phoneNumber"
              @input="inputPhoneNumber"
              placeholder="08012812938"
              name="user[phone_number]"
              max="11"
            )
            p.help.is-danger(v-for="err in phoneNumberErrors") {{ err }}


        // TODO: メアドのバリデーション


      .has-text-centered.submitBtn
        button.button.h-customize.h-is-blur(
          :disabled="isButtonDisabled"
        ) 会員登録に進む
  template(v-if="step === 2")
    h1.has-text-centered 会員登録確認メール送信
    p.subtitle
      | ご登録のメールアドレスに確認メールを送信しました。
      br
      br
      | 登録のお手続きはまだ完了しておりません。
      br
      | 確認メールに記載の URL から登録手続きを行ってください。
      br
      br
      | ※ URLの有効期限は配信後1時間以内です。
      br
      br
      | ご案内メールが届かない場合、メールアドレスに誤りがある可能性があります。
      | お手数をおかけしますがもう一度ご入力をお願い致します。
      br
      | また、正しいメールアドレスを入力をしてもメールが届かない場合は
      a(href="mailto:support@hashpick.net" target="_blank") support@hashpick.net
      | までお問い合わせください。
  template(v-if="step === 0")
    h1.has-text-centered このメールアドレスはすでに登録済みです
    p.subtitle
</template>

<script>
import validaters from '../../../mixins/validaters';

export default {
  mixins: [validaters],
  data() {
    return {
      email: null,
      emailConfirmation: null,
      firstName: null,
      lastName: null,
      kanaFirstName: null,
      kanaLastName: null,
      companyName: null,
      phoneNumber: null,
      teamName: '',
      teamCode: '',

      step: 1,
      emailErrors: [],
      emailConfirmationErrors: [],
      lastNameErrors: [],
      firstNameErrors: [],
      kanaLastNameErrors: [],
      kanaFirstNameErrors: [],
      companyNameErrors: [],
      phoneNumberErrors: [],
      teamNameErrors: [],
      teamCodeErrors: [],
    };
  },
  computed: {
    isButtonDisabled() {
      if(([this.email, this.emailConfirmation, this.firstName, this.lastName,
            this.kanaFirstName, this.kanaLastName, this.companyName, this.phoneNumber].includes(null))){
            return true
          }
      if(this.teamName == '' || this.teamCode == ''){
            return true
          }
      if((this.emailErrors + this.emailConfirmationErrors +
          this.lastNameErrors + this.firstNameErrors +
          this.kanaLastNameErrors + this.kanaFirstNameErrors +
          this.companyNameErrors + this.phoneNumberErrors + this.teamNameErrors + this.teamCodeErrors).length > 0) {
            return true
          }

      return false
    }
  },
  methods: {
    async submitForm(e) {
      const registrationEmail = await this.$store.dispatch('sentRegistrationEmail', {
        user: {
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
          kana_first_name: this.kanaFirstName,
          kana_last_name: this.kanaLastName,
          company_name: this.companyName,
          phone_number: this.phoneNumber,
        },
        team: {
          name: this.teamName,
          code: this.teamCode
        }
      });

      this.step = 2
      this.pushToDataLayer()
    },
    inputEmail(event) {
      this.emailErrors = []
      this.email = event.target.value
      if(this.email === '') { this.emailErrors.push('メールアドレスを入力してください。') }
      if(!this.email.includes('@')) { this.emailErrors.push('@を含む正しいメールアドレスを入力してください。') }
    },
    inputEmailConfirmation(event) {
      this.emailConfirmationErrors = []
      this.emailConfirmation = event.target.value
      if(this.emailConfirmation === '') { this.emailConfirmationErrors.push('確認用メールアドレスを入力してください。') }
      if(!this.emailConfirmation.includes('@')) { this.emailConfirmationErrors.push('@を含む正しいメールアドレスを入力してください。') }
      if(this.email !== this.emailConfirmation) { this.emailConfirmationErrors.push('入力したメールアドレスが異なります。') }
    },
    inputLastName(event) {
      this.lastNameErrors = []
      this.lastName = event.target.value
      if(this.lastName === '') {
        this.lastNameErrors.push('姓を入力してください。')
      }
    },
    inputFirstName(event) {
      this.firstNameErrors = []
      this.firstName = event.target.value
      if(this.firstName === '') {
        this.firstNameErrors.push('名を入力してください。')
      }
    },
    inputKanaLastName(event) {
      this.kanaLastNameErrors = []
      this.kanaLastName = event.target.value
      if(this.kanaLastName === '') {
        this.kanaLastNameErrors.push('ふりがな(姓)を入力してください。')
      }
    },
    inputKanaFirstName(event) {
      this.kanaFirstNameErrors = []
      this.kanaFirstName = event.target.value
      if(this.kanaFirstName === '') {
        this.kanaFirstNameErrors.push('ふりがな(名)を入力してください。')
      }
    },
    inputCompanyName(event) {
      this.companyNameErrors = []
      this.companyName = event.target.value
      if(this.companyName === '') {
        this.companyNameErrors.push('企業名を入力してください。')
      }
    },
    inputPhoneNumber(event) {
      this.phoneNumberErrors = []
      this.phoneNumber = event.target.value
      this.phoneNumberErrors = this.checkPhoneNumber(this.phoneNumber, this.phoneNumberErrors)
    },
    inputTeamName(event) {
      this.teamNameErrors = []
      this.teamName = event.target.value
      if(this.teamName === '') {
        this.teamNameErrors.push('チーム名を入力してください。')
      }
    },
    onBlurTeamName() {},
    inputTeamCode(event) {
      this.teamCodeErrors = []
      this.teamCode = event.target.value
      if(this.teamCode === '') {
        this.teamCodeErrors.push('チームコードを入力してください。')
      }
    },
    async onBlurTeamCode() {
      if (this.teamCode !== '') {
        const is_exists = await this.$store.dispatch('checkTeamCodeUniq', {
          code: this.teamCode
        });
        if (is_exists == true) {
          this.teamCodeErrors.push('チームコードはすでに存在します')
        }
      }
    },
    pushToDataLayer() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'user_registration',
        'user[email]': this.email,
        'user[email_confirmation]': this.emailConfirmation,
        'team[name]': this.teamName,
        'team[code]': this.teamCode,
        'user[last_name]': this.lastName,
        'user[first_name]': this.firstName,
        'user[kana_last_name]': this.kanaLastName,
        'user[kana_first_name]': this.kanaFirstName,
        'user[company_name]': this.companyName,
        'user[phone_number]': this.phoneNumber
      });
    }
  },
};
</script>

<style lang="scss">
.subtitle {
  margin: 24px 0;
}
#app-email-confirmation {
  padding: 50px 0;
}
</style>
