import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.url = new URL(window.location)
  }

  companyNameFilter(event) {
    this.url.searchParams.set('company_name', event.currentTarget.value)
    window.location.href = this.url.toString()
  }

  teamNameFilter(event) {
    this.url.searchParams.set('team_name', event.currentTarget.value)
    window.location.href = this.url.toString()
  }

  teamIdFilter(event) {
    this.url.searchParams.set('team_id', event.currentTarget.value)
    window.location.href = this.url.toString()
  }
}