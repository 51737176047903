import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String,
    targetDate: String,
    demographicType: String
  }

  async connect() {
    this.localeType = 'city';
    this.initGraph(
      await this.fetchDatasets()
    )
  }

  initGraph(dataGroup) {
    const options = {
      indexAxis: 'y',
      scales: {
        y:
          {
            ticks: {
              fontColor: '#637178',
            }
          },
        x:
          {
            grid: {
              display: false,
              color: '#E3E8EB',
            }
          }
      },
      responsive: true,
      plugins: {
        legend : {
          display : false
        }
      },
      interaction: {
        intersect: false
      }
    }

    const sortedDataGroup = Object.entries(dataGroup).sort((a, b) => {
      return a[1] < b[1] ? 1 : -1;
    })

    const labels = []
    const datasets = []

    sortedDataGroup.forEach((data, index) => {
      if(index > 5) { return }

      labels.push(data[0].replace('Prefecture', ''))
      datasets.push(
        data[1]
      )
    })

    const data = {
      labels,
      datasets: [{
        data: datasets,
        backgroundColor: 'rgba(122, 128, 201, 1)'
      }]
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    ctx.canvas.height = 280;
    this.chart = new Chart(
      ctx, {
      type: 'bar',
      data,
      options
    })
  }

  async handleChange(event) {
    this.localeType = event.currentTarget.value

    const sortedDataGroup = Object.entries(await this.fetchDatasets()).sort((a, b) => {
      return a[1] < b[1] ? 1 : -1;
    })

    const labels = []
    const datasets = []

    sortedDataGroup.forEach((data, index) => {
      if(index > 5) { return }

      labels.push(data[0].replace('Prefecture', ''))
      datasets.push(
        data[1]
      )
    })

    this.chart.data.labels = labels
    this.chart.data.datasets = [{
      data: datasets,
      backgroundColor: 'rgba(122, 128, 201, 1)'
    }]

    this.chart.update();
  }

  async downloadCsv() {
    // TODO: 共通化した方がいいかも

    const csvString = this.csvHeaders() + Object.entries(await this.fetchDatasets()).map((data) => {
      // カンマを含むデータが入ってくるので加工
      return [`"${data[0]}"`, data[1]].join(',')
    }).join("\n")

    const fileName = `audience_${this.localeType}`
    const fileNameWithDate = `${fileName}_download_${moment(this.targetDateValue).format('YYYYMMDD')}`
    // UTF BOM
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([bom, csvString]);
    if (window.navigator.msSaveOrOpenBlob){
      window.navigator.msSaveBlob(blob, fileNameWithDate + '.csv');
    }
    else {
      const aTag = window.document.createElement("a");

      aTag.href = window.URL.createObjectURL(blob, {
        type: "text/csv"
      });
      aTag.download = fileNameWithDate + '.csv';
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
    }
  }

  async fetchDatasets() {
    let endpoint;
    if(this.endpointValue.includes('?')) {
      endpoint = this.endpointValue + `&locale_type=${this.localeType}`
    }else{
      endpoint = this.endpointValue + `?locale_type=${this.localeType}`
    }

    const res = await fetch(endpoint)
    return await res.json()
  }

  csvHeaders() {
    let targetNumber = ''
    switch (this.demographicTypeValue) {
      case 'reached_audience_demographics':
        targetNumber = "リーチ数";
        break;
      case 'engaged_audience_demographics':
        targetNumber = "エンゲージメント数";
        break;
      case 'follower_demographics':
        targetNumber = "フォロワー数";
        break;
    }
    let region = this.localeType === 'city' ? '都道府県+都市': '国コード'

    return [region, targetNumber].join(',') + "\n"
  }
}

