<template lang="pug">
#app-users-profile
  template(v-if="step === 1")
    h1.has-text-centered パスワード登録
    form(@submit.prevent="submitForm")
      .field.is-horizontal.is-align-items-center
        .field-label.h-customize
          label.label メールアドレス
        .field-body.h-customize
          .field
            input.input(
              :value="email"
              type="email"
              required
              disabled
            )

      .field.is-horizontal.is-align-items-center
        .field-label.h-customize
          label.label
            = "パスワード"
            .is-size-7 （半角英数記号のみ8文字以上）
        .field-body.h-customize
          .field
            input.input(
              :value="password"
              @input="inputPassword"
              type="password"
            )
            p.help.is-danger(v-for="err in passwordErrors") {{ err }}

      .field.is-horizontal.is-align-items-center
        .field-label.h-customize
          label.label パスワード確認用
        .field-body.h-customize
          .field
            input.input(
              :value="passwordConfirmation"
              @input="inputPasswordConfirmation"
              type="password"
            )
            p.help.is-danger(v-for="err in passwordConfirmationErrors") {{ err }}

      p.has-text-centered
        a(:href="$store.state.termsUrl" target="_blank") 利用規約
        |  をご確認のうえ、「規約に同意する」をチェックしてください。
      .has-text-centered.termOfService.mb-5
        label
          input(
            type="checkbox"
            v-model="isCheckedTermsOfService"
          )
          | 規約に同意する

      .has-text-centered
        button.button.h-customize.h-is-blue(
          :disabled="isButtonDisabled"
        ) 完了する
  template(v-if="step === 2")
    h1.has-text-centered 会員登録完了
    p
      | 会員登録がありがとうございます。
      br
      | 以下のステップでサービスの利用を開始してください。
    ol
      li
        | 次の画面で利用するプランを選択してください。
        br
        | （※無料トライアル期間がございますので、ご安心して登録ください）
      li hashpickで使っている決済システムであるstripeに必要な情報を入力・登録をしてください。
      li 登録が完了しましたら、facebook認証をしてください。
      li facebook認証完了後、サービス開始の準備ができます。
      li hashpickを使って、貴社のSNSのPDCAに役立ててください。
    .has-text-centered
      button.button.is-text(
        @click="redirectDashboard"
      ) サービス利用を開始する
  template(v-if="step === 0")
    h1.has-text-centered 会員情報の登録に失敗しました。
</template>

<script>
import manageParams from '../../../mixins/params';
import validaters from '../../../mixins/validaters';

export default {
  mixins: [manageParams, validaters],
  data() {
    return {
      email: '',
      password: null,
      passwordConfirmation: null,

      step: 1,
      ott: '',
      teamId: null,
      isCheckedTermsOfService: false,

      passwordErrors: [],
      passwordConfirmationErrors: [],

    }
  },
  computed: {
    isButtonDisabled() {
      // null が1つでもあるとtrue
      if([this.email, this.password, this.passwordConfirmation].includes(null)){
            return true
          }
      // errors が1つでもあるとtrue
      if((this.passwordErrors + this.passwordConfirmationErrors).length > 0) {
            return true
          }
      // パスワードが8文字以上ないとtrue
      if(this.password.length < 8) { return true }
      if(this.password !== this.passwordConfirmation) { return true }

      if(!this.isCheckedTermsOfService) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.email = this.getParams().email.replace(' ', '+')  // エイリアス対策
    this.ott = this.getParams().ott
  },
  methods: {
    async submitForm() {
      const {is_complete, team_id} = await this.$store.dispatch('patchUserProfile', {
        user: {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        },
        ott: {
          value: this.ott
        }
      })

      if(is_complete) {
        this.step = 2
        this.teamId = team_id
      }else{
        this.step = 0
      }
    },

    redirectDashboard() {
      // current_users_connect_path
      location.href = `/teams/${this.teamId}/instagram_accounts`
    },

    inputPassword(event) {
      this.passwordErrors = []
      this.password = event.target.value
      if(this.password === '') {
        this.passwordErrors.push('パスワードを入力してください。')
      }
    },
    inputPasswordConfirmation(event) {
      this.passwordConfirmationErrors = []
      this.passwordConfirmation = event.target.value
      if(this.passwordConfirmation === '') {
        this.passwordConfirmationErrors.push('パスワード確認欄を入力してください。')
      }
      if(this.passwordConfirmation !== this.password) {
        this.passwordConfirmationErrors.push('パスワードが一致しません。正しいパスワードを入力してください。')
      }
    },
  }
};
</script>
