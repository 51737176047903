import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import axios from "axios";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String,
  }

  async connect(){
    const res = await axios.get(this.endpointValue)
    const points = res.data

    const labels = []
    const values = []
    const datasets = []

    points.forEach((point) => {
      labels.push(point[0])
      values.push(point[1])
    })

    const options = {
      scales: {
        y:
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              min: 0,
              fontColor: '#637178',
              precision: 0,
              callback: function(label, index, labels) {
              return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }
          },
        x:
          {
            grid: {
            display: false,
            color: '#E3E8EB',
            },
            ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 30,
            minRotation: 0,
            fontColor: '#637178',
            }
          }
      },
      elements: {
        point:{
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend : {
          display : false
        }
      }
    }

    datasets.push({
      label: 'ストーリーズ@メンション',
      fill: false,
      data: values,
      borderColor: '#757DCB',
      backgroundColor: '#757DCB',
      lineTension: 0,
    })

    const data = {
      datasets,
      labels
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    ctx.canvas.height = 300;
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }
}
