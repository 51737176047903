import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import axios from "axios";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String,
  }

  async connect(){
    const res = await axios.get(this.endpointValue)
    const points = res.data

    let datasets = []
    const labels = []
    const values = []
    points.forEach((point) => {
      labels.push(point[0])
      values.push(point[1])
    })

    const options = {
      scales: {
        y:
          {
            min: 1,
            max: 50,
            fontColor: '#637178',
            reverse: true,
            display: false
          },
        x:
          {
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 30,
              minRotation: 0,
              fontColor: '#637178',
              display: false
            }
          }
      },
      elements: {
        point:{
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend : {
          display : false
        }
      }
    }

    datasets.push({
      label: '',
      fill: false,
      data: values,
      borderColor: '#757DCB',
      backgroundColor: '#757DCB',
    })

    const data = {
      labels,
      datasets
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    ctx.canvas.height = 150;
    this.chart = new Chart(
      ctx, {
      type: 'line',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }
}
