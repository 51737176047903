import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitBtn']

  connect() {
    this.keyword = ''
  }

  handleKeyword(event) {
    this.keyword = event.currentTarget.value
    this.handleInput()
  }

  handleInput() {
    let isDisabled = false

    if(this.keyword.length === 0) { isDisabled = true }
    if(this.element.dataset.disabled === 'true') { isDisabled = true }

    this.submitBtnTarget.disabled = isDisabled
  }
}