<template lang="pug">
#app-users-password-reset-new
  template(v-if="step === 1")
    h1.has-text-centered パスワードをお忘れですか?
    p.has-text-centered-tablet
      | 入力したアドレスにパスワード変更用のURLをお送りします。<br>
      | 添付されている URL より、パスワード変更を行ってください。
      br
      | ※ URLの有効期限は配信後1時間以内です。
    form(@submit.prevent="checkForm")
      .field.is-horizontal.is-align-items-center.mb-5
        .field-label.h-customize
          label メールアドレス
        .field-body.h-customize
          .field
            input.input(
              placeholder="hashpick@hottolink.com"
              @input="inputEmail"
              :value="email"
            )
            p.help.is-danger(v-for="err in emailErrors") {{ err }}
      .has-text-centered
        button.button.h-customize.h-is-blue(
          :disabled="isButtonDisabled"
          :class='{"is-loading": isSubmitting}'
        ) パスワードの再設定を行う
  template(v-else)
    h1.has-text-centered パスワード再設定メール送信
    p.subtitle
      | ご登録のメールアドレスにパスワード再設定用のメールを送信しました。
      br
      | 確認メールに記載の URL からパスワード再設定を行ってください。
      br
      br
      | ※ URLの有効期限は配信後1時間以内です。
      br
      br
      | ご案内メールが届かない場合、メールアドレスに誤りがある可能性があります。
      | お手数をおかけしますがもう一度ご入力をお願い致します。
</template>

<script>
export default {
  data() {
    return {
      email: null,
      step: 1,
      isSubmitting: false,

      emailErrors: []
    }
  },
  computed: {
    isButtonDisabled() {
      if(([this.email].includes(null))){
        return true
      }

      if((this.emailErrors).length > 0) {
        return true
      }
      return false
    }
  },
  methods: {
    async checkForm() {
      this.isSubmitting = true
      const sendEmail = await this.$store.dispatch('sentPasswordResetEmail', {
        email: this.email
      })
      this.isSubmitting = false

      if(sendEmail) {
        this.step = 2
      } else {
        this.step = 0
      }
    },
    inputEmail(event) {
      this.emailErrors = []
      this.email = event.target.value
      if(this.email === '') { this.emailErrors.push('メールアドレスを入力してください。') }
      if(!this.email.includes('@')) { this.emailErrors.push('@を含む正しいメールアドレスを入力してください。') }
    }
  }
};
</script>
