import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async connect() {
    const mediaUrl = this.element.dataset.mediaUrl;
    const isValid = await this.isValidUrl(mediaUrl);
    
    if (!mediaUrl || !isValid) {
      this.displayError();
    }
  }

  async isValidUrl(url) {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  }

  displayError() {
    const errorHTML = `
      <div class="h-forbidden-media-icon mx-auto mb-2"></div>
      <p class="mb-1 message-error">hashpick上で表示できません。</p>
      <p class="mb-1 message-error">Instagram上でご確認ください。</p>
    `;
    this.element.innerHTML = errorHTML;
  }
}