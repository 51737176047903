import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dropDown']

  connect() {
    this.url = new URL(window.location)

    this.dropDownTargets.forEach(item => {
      item.addEventListener('blur', (event) => {
        item.classList.remove('h-open')
      });
    })
  }

  toggleDropdown(event) {
    const classList = event.currentTarget.classList
    if (classList.contains('h-open')){
      event.currentTarget.classList.remove('h-open')
    } else {
      event.currentTarget.classList.add('h-open')
    }
  }

  teamNameFilter(event) {
    this.url.searchParams.set('team_name', event.currentTarget.value)
    window.location.href = this.url.toString()
  }

  teamIdFilter(event) {
    this.url.searchParams.set('team_id', event.currentTarget.value)
    window.location.href = this.url.toString()
  }

  planFilter(event) {
    this.url.searchParams.set('plan_id', event.currentTarget.value)
    window.location.href = this.url.toString()
  }
}