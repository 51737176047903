import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['displayModal']
  static values = {
    tableId: String,
  }
  connect(){
    this.table = document.getElementById(this.tableIdValue);
    document.querySelectorAll('.dropdown-check-list').forEach(item => {
      item.addEventListener('blur', (event) => {
        this.displayModalTarget.classList.remove('visible')
      });
    })

  }

  toggleDisplaySetting(event){
    // return if click on display items
    if (event.target.type == 'radio'){
      return
    }
    const classList = this.displayModalTarget.classList
    if (classList.contains('visible')){
      this.displayModalTarget.classList.remove('visible')
    }
    else {
      this.displayModalTarget.classList.add('visible')
    }
  }

  toggleTableColumn(event){
    const column = event.target.parentElement
    const index = this.getIndex(column)
    event.target.checked ? this.showColumn(index) : this.hideColumn(index);
  }

  hideColumn (index) {
    const cells = [].slice.call(this.table.querySelectorAll('th, td'));
    cells
      .filter( (cell) => {
        const cell_idx =  this.getIndex(cell)
        return cell_idx === index;
      })
      .forEach((cell) => {
        cell.style.display = 'none';
      });
  };

  showColumn(index) {
    const cells = [].slice.call(this.table.querySelectorAll('th, td'));
    cells
      .filter( (cell) => {
        return this.getIndex(cell) === index;
      })
      .forEach( (cell) => {
        cell.style.display = '';
      });
  };

  getIndex(el){
    return [...el.parentElement.children].indexOf(el);
  }
}
