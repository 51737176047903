import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.displayMenu();
    }

    displayMenu() {
        const filterBox = document.querySelector('.filter-box');
        const filterHeader = filterBox.querySelector('.filter-header');
        const radioFilterButtons = filterBox.querySelectorAll('input[type="radio"]');
        const filterTitle = filterBox.querySelector('.dropdown-title');
        
        const defaultFilterChecked = filterBox.querySelector('input[type="radio"]:checked');
        if (defaultFilterChecked) {
            filterTitle.textContent = defaultFilterChecked.value;
        }

        filterHeader.addEventListener('click', function() {
            filterBox.classList.toggle('active-filter');
        });

        radioFilterButtons.forEach(function(radio) {
            radio.addEventListener('change', function() {
                filterTitle.textContent = radio.value;
                filterBox.classList.remove('active-filter'); 
            });
        });

        document.addEventListener('click', function(event) {
            if (!filterBox.contains(event.target)) {
            filterBox.classList.remove('active-filter');
            }
        });
    }
}