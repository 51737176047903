import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['graphArea']

  static values = {
    endpoint: String
  }

  async connect() {
    const res = await fetch(this.endpointValue)
    const points = await res.json()

    const options = {
      scales: {
        y:
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              min: 0,
              fontColor: '#637178',
              precision: 0,
            }
          },
        x:
          {
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 30,
              minRotation: 0,
              fontColor: '#637178',
            }
          }
      },
      elements: {
        point:{
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend : {
          display : false
        }
      }
    }

    const data = {
      datasets: [{
        label: '男性',
        fill: false,
        data: points[1],
        backgroundColor: 'rgba(122, 128, 201, 1)',
        lineTension: 0
      },{
        label: '女性',
        fill: false,
        data: points[2],
        backgroundColor: 'rgba(192, 64, 134, 1)',
        lineTension: 0
      },{
        label: 'その他',
        fill: false,
        data: points[3],
        backgroundColor: 'rgba(214, 220, 83, 1)',
        lineTension: 0
      }],
      labels: points[0]
    }

    const ctx = this.graphAreaTarget.getContext('2d');
    this.chart = new Chart(
      ctx, {
      type: 'bar',
      cubicInterpolationMode: 'monotone',
      data,
      options
    })
  }
}
