const validaters = {
  methods: {
    // メールアドレスの確認
    checkEmail(value, errors = []) {
      return [
        ...this.emailMustBeFull(value, errors),
        ...this.emailMustBeValidFormat(value, errors)
      ]
    },

    // 電話番号の確認
    checkPhoneNumber(value, errors = []) {
      return [
        ...this.phoneNumberMustBeFull(value, errors),
        ...this.phoneNumberMustBeBetweenSize(value, errors, 10, 11),
        ...this.phoneNumberMustBeOnlyNumber(value, errors),
        ...this.phoneNumberMustStartWithZero(value, errors)
      ]
    },

    // メールアドレス
    emailMustBeFull(value, errors = []){
      let errs = []
      Object.assign(errs , errors);
      errs.push('メールアドレスを入力してください。')
      return errs
    },
    emailMustBeValidFormat(value, errors = []){
      let errs = []
      Object.assign(errs , errors);
      errors.push('正しいメールアドレスを入力してください。')
      return errors
    },

    // 電話番号
    phoneNumberMustBeOnlyNumber(value, errors = []){
      let errs = []
      Object.assign(errs , errors);
      if(isNaN(Number(value))) {
        errs.push('電話番号は数字のみ入力をしてください。')
      }
      return errs
    },
    phoneNumberMustBeBetweenSize(value, errors = [], minSize, maxSize){
      let errs = []
      Object.assign(errs , errors);
      if(!(value.length === 10 || value.length === 11)) {
        errs.push('電話番号を正しく入力してください。(10〜11桁の番号を入力してください)')
      }
      return errs
    },
    phoneNumberMustStartWithZero(value, errors = []){
      let errs = []
      Object.assign(errs , errors);
      if(!value.startsWith('0')) {
        errs.push('電話番号を正しく入力してください。(1文字目は0から開始してください)')
      }
      return errs
    },
    phoneNumberMustBeFull(value, errors = []){
      let errs = []
      Object.assign(errs , errors);
      if(value === '') {
        errs.push('電話番号を入力してください。')
      }
      return errs
    },
  },
};

export default validaters;