import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if(this.disableDeleteButton === 'true') { this.deleteBtnHide(); }
    if(this.hideTime === 'none') { return; }

    setTimeout(() => {
      this.hide()
    }, this.hideTime)
  }

  hide(){
    this.element.style.display = 'none'
  }

  deleteBtnHide() {
    this.element.querySelector('button.delete').style.display = 'none'
  }

  get hideTime() {
    return this.element.dataset.hideTime || 3000;
  }

  get disableDeleteButton() {
    return this.element.dataset.disableDeleteButton;
  }
}
