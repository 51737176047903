export function downloadCsvFromCharts(chartData, csvFileName) {
  let csvHeaders = ['年月日']
  let datasets = []
  let dates = chartData[0].labels
  chartData.forEach(chart => {
    datasets = datasets.concat(chart.datasets)
  })
  datasets = datasets.flat()
  let csvValues = []
  dates.forEach((date, idx) => {
    let vals = [date]
    datasets.forEach(dataset => {
      vals.push(dataset.data[idx])
      if(!csvHeaders.includes(dataset.label)) {
        csvHeaders.push(dataset.label)
      }
    })
    csvValues.push(vals.join(','))
  })

  let csvData = csvHeaders.join(',') + "\n" + csvValues.join("\n")
  startDownloadCsv(csvData, csvFileName)
}

export function downloadCsvFromAllCharts(chartData, csvFileName) {
  let csvHeaders = ['年月日']
  let datasets = []
  let dates = chartData[0].labels
  chartData.forEach(chart => {
    datasets = datasets.concat(chart.datasets)
    if (!csvHeaders.includes(chart.chartName)) {
      csvHeaders.push(chart.chartName);
    }
  })
  datasets = datasets.flat()
  let csvValues = []
  dates.forEach((date, idx) => {
    let vals = [date]
    datasets.forEach(dataset => {
      vals.push(dataset.data[idx])
    })
    csvValues.push(vals.join(','))
  })

  let csvData = csvHeaders.join(',') + "\n" + csvValues.join("\n")
  startDownloadCsv(csvData, csvFileName)
}

function startDownloadCsv(csvData, csvFileName) {
  // UTF BOM
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const blob = new Blob([bom, csvData]);
  if (window.navigator.msSaveOrOpenBlob){
    window.navigator.msSaveBlob(blob, csvFileName + '.csv');
  }
  else {
    const aTag = window.document.createElement("a");

    aTag.href = window.URL.createObjectURL(blob, {
      type: "text/csv"
    });
    aTag.download = csvFileName + '.csv';
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  }
}
