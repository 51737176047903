import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.displayFilter();
  }
  displayFilter(){
    const button = document.querySelector('.button-details');
    let filter =  document.querySelector('.filter-body');
    let svg = button.querySelector('svg');
    button.addEventListener("click", (event) => {
        if(filter.style.display == "block" || filter.style.display == ""){
            filter.style.display = "none";
            svg.style.transform = "rotate(0deg)";
        }else{
            filter.style.display = "block";
            svg.style.transform = "rotate(180deg)";
        }
    });
  }
}
